.alert {
    position: fixed;
    z-index: 1000;
    bottom:0;
    right:0;
    left:0;
}
@media only screen and (min-width:961px) {
    .alert {
        left:$tablet_menu_width;
    }
}
@media only screen and (min-width: 1200px){ 
    .alert {
        left:$desktop_menu_width;
    }
}