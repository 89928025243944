.justify-content-bottom {
    justify-content:flex-end;
    height:100%;
    display:flex;
    flex-direction:column;
}
.scroll-bg {
    background-attachment:scroll;
}
.fix-bg {
    background-attachment:fixed;
}
.text-right {
    text-align: right;
}
.text-uppercase {
    text-transform: uppercase;
}