@import '_colours.scss';
@import '_constants.scss';
@import '_utils.scss';

html {
    color: #222;
    font-size: 16px;
    line-height: 1.2;
    height: 100%;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}


/* ==========================================================================
   Author's custom styles
   ========================================================================== */

body {
    padding:0;
    margin:0;
    font-size:16px;
    height:100%;
}
a {
    text-decoration:none;
    color: $brand-blue;
    [href^="#"]{
         /* anchor links like more-links */
        color: $brand-pink;
        transition: color 0.3s ease;
        margin-top: 1em;
        cursor: pointer;
        transition: color 0.3s ease;
        &:hover {
            color: $brand-blue;
        }
    }
}
.teaser,
.bg-overlay {
    a, a[href^="#"] {
        color: inherit;
    }
}

.contentwrap {
    max-width:1200px;
    margin: 0 auto;
    position:relative;
    padding: 20px;
}

img {
    border:none;
}
a.logo {
    display: inline-block;
    box-sizing: border-box;
    h1 {
        margin-top: 0;
        margin-bottom: 0;
    }
    img {
        width: 100px;
        max-width: 100%;
    }
}


/** site links **/
.site-link {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 5px;
}
.site-body {
    height:100%;
    overflow:visible;
}
main {
    /* mobile */
    position: relative;
    left: 0px;
    box-sizing: border-box;
    > * {
        background-size:cover;
        background-position:center;
        box-sizing: border-box;
    }
    /* desktop in media */
}

/* embelishment shapes */
div {
/* some general */
    &.top,
    &.bottom {
        position: absolute;
        pointer-events: none;
        z-index: 2;
    }
    &.top {
        top: 0px;
    }
    &.bottom {
        bottom: 10px;
    }
    .left { /* overridden for desktop in media Qs */
        left: 5%;
    }
    .middle {
        left: 50%;
    }
    .right {
        left: 90%;
    }
    .di { /* diagonal */
        transform: rotate(45deg);
    }
/** shapes generic **/
    .dots {
        @include dot_matrix;
        /* in base */
        &.top.left { /* rest is right */
            top: 120px;
            left: -74px;
        }
    }
    .circle {
        @include circle;
        &.yellow {
            background-color: $brand-yellow;
        }
    }
    .tri {
        @include triangle;
    }
    .seg {
        @include segment;
    }
    .rect {
        @include rectangle;
    }
    .slash {
        @include slash_matrix;
    }
    .rec2 {
        @include rec2;
    }
/** screen 1 **/
    .dots {
        &.middle.top {
            top: -100px;
            left: 46.5%;
        }
        &.right {
            top: 120px;
            right: -74px;
            left: auto;
        }
    }
    .circle {
        &.top {
            top: -65px;
            left: 52%;
        }
    }
    .tri {
        &.bottom {
            left: 32%;
            bottom: 90px;
            &.middle {
                /* point down (rotate the one not moving)*/
                transform: rotate(315deg);
            }
        }
    }
    .seg {
        &.bottom {
            left: 37%;
            bottom: 100px;
            transform: rotate(135deg);
        }
    }
    .pink-seg {
        @include pink-segment;
        /* svg instead of css */
        top: 5px;
        left: 32%;
    }
    .slash {
        &.middle {
            left: 32%;
            bottom: 10px;
        }
    }
/** screen 2 **/
    .dots {
        &.s2 {
            &.left {
                left: 30%;
                top: -60px;
            }
            &.right {
                top: 60px;
                right: 0px;
                left: auto;
            }
        }
        &.bottom {
            left: 1%;
            bottom: 5%;
        }
    }
    .rect {
        &.bottom {
            left: 2%;
            bottom: 10%;
        }
    }
    .circle {
        &.bottom {
            left: 80%;
            bottom: 5%;
        }
    }
    .tri {
        &.right {
            left: 81%;
            bottom: 0px;
        }
    }
/** screen 3 **/
    .rec2 {
        top: 45px;
        left: 1%;
    }
    .rect.top {
        left: 40%;
        top: 40px;
    }
    .slash.h {
        left: 8%;
        bottom: -114px;
    }
    .dots.middle.bottom {
        left: 46%;
    }
}
/** mouse move **/
.flare-wrap {
    position: absolute; /* to main */
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    pointer-events: none;
    overflow: hidden;
}
.flare {
    position: relative;
    width: 100vw;
    width: calc(100vw - #{$desktop_menu_width});
    height: 100%;
    transition: translate 1s linear; /* mouse */
    pointer-events: none;
    z-index:3;
}
.scroll-shapes {
    transition: translateX 0.5s ease-in-out;
}

/* svg animations */

#sdg-home {
    display: block;
    max-width: 90%;
    margin: 0 auto;
    width:600px;
    height: 365px;
    &.active {
        .st, .sb, .gt, .gb {
            animation: draw 16s linear 0.3s forwards;
        }
        .d {
            animation: draw 11s linear 0.3s forwards;
        }
    }
}
@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}

/** shared padding */
.half-pane,
.slide-menu .menu {
    /* desktop */
    padding: 80px;
    padding: 6vw;
    padding-top: 100px; /* header icon */
}
.contact-map {
    position: relative;
    padding: 0px;
    &:before {
        @include darken_before;
        pointer-events: none;
        z-index: 1;
    }
}

/* half panes */
.half-pane,
.slide-menu .menu {
    position: relative;
    box-sizing: border-box;
    /* desktop sizes in media */
}
.half-pane {
    background-size:cover;
    background-position:center;
    font-size: 16px;
    font-size: max(16px, 0.9vw);
}
.split-content.justify-content-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.top-logo,
.bottom-logo {
    display:none;
}
/*
.top-logo,
.bottom-logo {
    position:fixed;
    left:0;
    right:0;
    height:20vh;
    background-image:url('../assets/logo.svg');
    background-repeat:no-repeat;
    background-position:center;
    background-size:contain;
    z-index:2;
}
.top-logo {
    top:-10vh;
    left: 50vw;
}
.bottom-logo {
    bottom:-10vh;
    right: 50vw;
}
*/

.bg-wrapper {
    position: relative; /* for shapes */
}
.bg-overlay,
.bg-overlay-navy,
.background-dark { /* text reverse */
    color: $text-light;
    h1, h2, h3 {
        color: inherit;
    }
    .intro-index {
        color: $text-light;
    }
}
.bg-overlay,
.bg-overlay-navy,
.background-dark {
    position:relative;
    background-color: grey;
    height: 100%;
    &::before {
        @include darken_before;
        z-index: 1;
        pointer-events: none;
    }
}
.bg-overlay-navy {
    &::before {
        background-color: rgba(0, 44, 57, 0.8);
    }
}
.background-dark {
    background-color: $text-dark;
    &::before {
    /* if teaser mode, shown on hover */
        background-color: rgba(0, 0, 0, 0);
    }
}
.pane-body { /* in every pane */
    position: relative;
    height: 100%;
    color: inherit;
    z-index: 3; /* content over bg + shapes */
    /** allow a to fill height **/
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    .scroll-hint {
        position: absolute;
        left: 0px;
        bottom: 0px;
        margin: 0px;
    }
    > a { /* teaser pane link */
        display: flex;
        flex-direction: column;
        height: 100%;
        flex-grow: 1;
    }
    /* push these down */
    .values.scroll-nav,
    .more-link {
        margin-top: auto; /* parent flex needed */
    }
}
.has-mob {
    .mob-image { /* only shown on vertical */
        display: none;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        z-index: 0;
    }
}


/** heading font **/
.intro-index, /*title + numbers */
.scroll-hint, /* bottom of pane */
.more-link, /* item read more links */
.page-heading, /* page titles &/or sub-titles */
.blog-categories,
.split-content a[href^="#"] {
    font-weight: 400;
}

/** small text **/
.intro-index, /*title + numbers */
.scroll-hint, /* bottom of pane */
.more-link, /* item read more links */
.page-title, /* item page titles */
.blog-categories,
.split-content a[href^="#"]{
    font-size: 20px;
    font-size: max(1vw, 20px);
    font-weight: 600;
}
.small {
    font-size: 16px;
}

/*============ header ============*/
header.top {
    /* mobile */
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 10px 30px;
    background:white;
    box-sizing: border-box;
    /* desktop in media */
    .iso-logo-wrapper{
        display: flex;
        gap:20px;
        flex-direction: column;
        align-items: center;
        .iso-combined{
            display: none;
        }
        @media only screen and (max-width: 400px) {
            margin-left: 0!important;
        }

    }
}
header.top a {
    text-decoration:none;
}
header {
    a.home {
        display: block;
        margin-top: 20px;
    }
}
header.top .title {
    /* mobile */
    font-size: 20px;
    color: $brand-blue;
    white-space:nowrap;
    font-weight: normal;
    line-height: 1px;
    /* desktop in media */
}
header.top .site-links {
    display:flex;
}
.iso-index{
    display: none;
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0.5rem 1.5rem;
    a{
        margin-right: auto;
    }
    .iso-logo-wrapper{
        display: flex;
        width: 100%;
        gap:1rem;
        justify-content: center;
        align-items: center;
        .iso-combined{
            display: none;
          
        }
        @media only screen and (max-width: 450px){
            .iso{
                display: none;
            }
            a{
                margin-right: 0;
            }
            .iso-combined{
                display: block;
            }
        }
    }
    @media only screen and (max-width: 960px), //or
       only screen and (max-height: 700px){
            display: block;
       }
}
.site-links {
    .site-link {
        position: relative;
        background-color: gray;
        transition: background-color 0.3s ease;
        img {
            vertical-align: top;
        }
        &:hover {
            background-color: $brand-pink;
        }
    }
}
#menu-button {
    width: 24px;
    height: 24px;
    margin: 2px;
    cursor: pointer;
    padding:20px;

    span {
        display: block;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background-color: #808080;
        margin: 6px 0;
        transition: all $timing;
    }
}
.slide-menu {
    /* mobile - desktop in MQ below */
    position:fixed;
    top:0;
    bottom:0;
    opacity:0;
    transition: transform $timing, opacity $timing;
    box-sizing:border-box;
    /* additional desktop + mobile in mediaQ below */
    ul {
        list-style:none;
        font-weight: 300;
        padding:0;
        margin: 0px;
        a {
            display: block;
            color:$text-light;
            text-decoration:none;
        }
    }
    li {
        > ul {
            display:none;
        }
        &:hover > a,
        &.active > a {
            color:$brand-yellow;
        }
        li {
             > a {
                padding-left: 20px;
            }
        }
    }
    .menu {
        h3 {
            position: relative;
            color: $text-highlight;
        }
        .close {
            display: none;
            position: absolute;
            top: 50%;
            margin-top: -12px;
            right: 15px;
            width: 24px;
            height: 24px;
            pointer-events: none; /* h3 triggers close */
            span {
                position: absolute;
                top: 50%;
                left: 0px;
                display: block;
                width: 100%;
                height: 2px;
                border-radius: 2px;
                background-color: $brand-pink;
                &.one {
                    -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }
                &.two {
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }
    }
    .menu-contact-form {
        display: none;
    }
}
@media only screen and (hover:none), //or
       only screen and (max-width: 960px), //or
       only screen and (max-height: 700px){
    /* vertical view or no hover state */
    .flare { /* no shapes */
        display: none;
    }
    .fix-bg { /* undo 'parallax' fx */
        background-attachment: scroll;
    }
    li.expand-active > ul {
        display:block;
    }
    ul .expand {
        position: absolute;
        top: 0;
        right: 0;
        border-left: 1px solid tint($text-dark, 20%);
        height: 50px;
        width: 50px;
        cursor: pointer;
    } 
    ul .expand i {
        content:"";
        display: block;
        height: 100%;
        width: 100%;
        background: url('../assets/menu-drop.svg') no-repeat center center;
        background-size: 30%;
        -webkit-transition: -webkit-transform 500ms ease;
        transition: -webkit-transform 500ms ease;
        transition: transform 500ms ease;
        transition: transform 500ms ease, -webkit-transform 500ms ease;
    }
    .expand-active > .expand i {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    .slide-menu {
        border-left: 1px solid tint($text-dark, 20%);
        h3 {
            padding: 20px;
            padding-left: 30px;
            padding-right: 40px;
            margin: 0px;
        }
        .menu > ul {
            border-bottom: 1px solid tint($text-dark, 20%);
        }
        ul {
            margin: 0px;
            a {
                padding: 12px 18px;
                padding-left: 30px;
            }
        }
        li {
            position: relative;
            &.top {
                > a {
                    border-top: 1px solid tint($text-dark, 20%);
                }
            }
            li {
                a {
                    padding-left: 40px;
                }
            }
        }
    }
}
@media only screen and (hover:hover)
       and (min-width: 961px)
       and (min-height: 701px){
/* has hover or is desktop mode */
    .slide-menu {
        li > ul {        
            display:block;
            max-height: 0;
            overflow:hidden;
            transition: all $timing;
            opacity: 0;
        }
    }
}
.menu-open {
    #menu-button {
        span.one {
            -webkit-transform: rotate(-45deg) translate(-4px, 4px);
            transform: rotate(-45deg) translate(-4px, 4px);
        }
        span.three {
             -webkit-transform: rotate(45deg) translate(-7px, -8px) ;
            transform: rotate(45deg) translate(-7px, -8px) ;
        }
        span.two {
            opacity: 0;
        }
    }
    .slide-menu {
        transform:translateX(0);
        opacity:1;
    }
}

/*============ footer ============*/
footer { /* only in mobile */
    display: none;
    font-size: 18px;
    text-align: center;
    padding: 20px 30px;
    box-sizing: border-box;
    a.logo {
        display: block;
        margin-bottom: 1em;
        img {
            display: block;
            margin: 0px auto;
        }
    }
}
/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}
.bykeo a {
    color: grey;
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}

.intro-index { /* p and h3 */
    display: flex;
    justify-content:space-between;
    align-items: center;
    color: $text-dark;
    margin: 1em 0px;
    .title:before {
        content: "/ ";
    }
    .index {
        font-size: 16px;
    }
}

/*============ splash ============*/
.scroll-hint {
    display: none;
}

#home {
    background-color: $text-dark;
}
.bg-overlay .scroll-hint,
.home_content .scroll-hint {
    color: $text-light;
}
.home_content {
    position: absolute;
    color: $text-light;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    z-index: 2;
    .half-pane {
        align-items:center;
    }
    h1, h2, h3 {
        color: inherit;
    }
    h1 {
        font-size: 48px;
        font-weight: 300;
    }
    .intro-index {
        color: $text-light;
        .title:before {
            display: none;
        }
    }
    .intro-phone {
        color: $text-light;
        a {
            color: inherit;
        }
    }
}

.home_logo {
    margin: 0px auto;
}

.scroll-icon {
    position: absolute;
    width: 80px;
    height: 80px;
    left: 4vw;
    bottom: 3vw;
    background-image: url('../assets/scroll-icon.svg');
    background-size: 70px 60px;
    background-repeat: no-repeat;
    background-position: left;
}

/** 'full-navy' template **/
.full-navy {
    /* align the text with other text */
    .page-summary {
        padding-top: 2em;
    }
}

/*============ page or shared-index ============*/
/* backgrounds in _utils.scss */
.page-heading {
    font-size: 20px;
    font-size: max(2.2vw, 20px);
    font-weight:300;
    padding: 1em 0px;
}
.t-index,
.t-item {
    .page-heading {
        display: flex;
        flex-direction: column;
        /* push title below subtitle - for projects */
        .title {
            order: 1;
        }
    }
}
/** related panes **/
$icon-size: 38px;
.expand-click,
.half-pane.teaser{
    cursor: pointer;
    border-left: $border;
    border-right: $border;
    /* over next expanding content */
    z-index: 1;
    &::before {
        transition: background-color $timing;
    }
    &:hover {
        &::before {
            background-color: rgba(0,0,0,0.5);
        }
    }
    .page-heading { /* space for icon */
        padding-right: $icon-size;
        .expander-open {
            top: auto;
        }
    }
}

/** expanding content (half-white) **/
.expanding {
    position: relative;
    margin-left: -100%;
    transition: margin-left $slide;
    /* -1 goes under main bg */
    z-index: -1;
    &.active {
        margin-left: 0px;
        z-index: 0;
    }
    &.on-top {
    /* over shapes */
        z-index: 3;
    }
}
/* expander icons */
.expander-open,
.expander-next,
.profile-close,
.expander-close {
    position: absolute;
    top: 0px;
    text-align: right;
    display: block;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    .icon {
        position: relative;
        display: inline-block;
        width: $icon-size;
        height: $icon-size;
        border-radius: 50%;
        border: 2px solid #CCC;
        transition: transform 1s ease,
                    border-color 0.3s ease;
        &:before,
        &:after {
            content:"";
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: #CCC;
            transition: background-color 0.3s ease;
        }
        &:before { /* vertical */
            width: 2px;
            margin-left: -1px;
            height: 20px;
            margin-top: -10px;
        }
        &:after { /* horizontal */
            height: 2px;
            margin-top: -1px;
            width: 20px;
            margin-left: -10px;
        }
        &:hover {
            border-color: $brand-blue;
            &:before,
            &:after {
                background-color: $brand-blue;
            }
        }
    }
}
.expander-next {
    .icon {
        &:before,
        &:after { /* both vertical */
            width: 2px;
            height: 12px;
            margin-left: 0px;
            transform-origin: center;
        }
        &:before {
            transform: rotate(45deg);
            margin-top: -2px;
        }
        &:after {
            transform: rotate(-45deg);
            margin-top: -10px;
        }
    }
}
.profile-close,
.expander-close {
    .icon {
        transform: rotate(45deg);
    }
}
.expander-open {
    .icon {
        border-color: #FFF;
        &:before,
        &:after {
            background-color: #FFF;
        }
        &:hover {
            border-color: $brand-yellow;
            &:before,
            &:after {
                background-color: $brand-yellow;
            }
        }
    }
}
.expand-click {
    .expander-open .icon {
        transform: rotate(0deg);
    }
    &.active {
        .expander-open .icon {
            transform: rotate(45deg);
        }
    }
}


.split-content-pane,
.white { /* staff profiles */
    background-color: $text-light;
}
.more-link {
    display: block;
    text-align: inherit;
    transition: color 0.3s ease;
    margin-top: 1em;
    a {
        cursor: pointer;
        color: $brand-pink;
        transition: color 0.3s ease;
        &:hover {
            color: $brand-blue;
        }
    }
}
.kmsgallery.sdg {
    .kmsgallery-thumbnail-image {
        display: none;
        width: 100%;
        height: auto;
        box-sizing: border-box;
        margin: 1em 0px;
        &.first {
            display: block;
        }
    }
}
.item-staff {
    &::before {
        color: #808080;
    }
}
.t-news {
    .item-staff {
        &:before {
            content: 'Author: ';
        }
        &.plural {
            &:before {
                content: 'Authors: ';
            }
        }
    }
}
.t-projects {
    .item-staff {
        &:before {
            content: 'Project Manager: ';
        }
        &.plural {
            &:before {
                content: 'Project Managers: ';
            }
        }
    }
}

/** first content pane **/
#split-item {
    padding-bottom: 8vw;
    padding-bottom: calc(8vw + 45px); /* back p */
    p.back {
        position: absolute;
        left: 6vw;
        right: 6vw;
        bottom: 8vw;
        max-width: 100%;
        padding-top: 1em;
        box-sizing: border-box;
    }
}

/* projects + blogs */
.pages {
    padding-top: 30px;
    .button {
        padding: 10px;
        min-width: 40px;
    }
}
.vertical-only { /** end of list pager **/
    display: none;
    .pages {
        text-align: center;
        padding: 30px 10%;
        padding-bottom: 60px;
    }
}

/*============ blog styles ============*/
.blog-categories {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    li {
        color: $brand-yellow;
        display: inline-block;
        vertical-align: middle;
        box-sizing: border-box;
        &:last-of-type {
            a:after {
                display: none;
            }
        }
        &.active {
            color: $brand-blue;
        }
        a {
            display: inline-block;
            min-height: 30px;
            color: inherit;
            transition: all 0.3s ease;
            &:after {
                content:"/";
                display: inline-block;
                padding: 0px 5px;
                color: $brand-yellow !important;
            }
            &:hover {
                color: $brand-blue;
            }
        }
    }
}

/*============ Team styles ============*/
/** staff profiles **/
.profile,
.desktop-pane {
    .page-heading {
        padding-bottom: 0px;
        min-height: 0px;
    }
    .position {
        font-size: 1.8em;
        color: $text-highlight;
    }
}

/*============ Value styles ============*/
.scroller-pane {
    .intro-index {
        visibility: hidden;
        margin-bottom: 20px;
    }
}
div.bg-circle {
    position: relative;
    width: 100%;
    &.scroll-shape {
        left: -50px;
    }
    &:before { /* darken circle */
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        padding-top: 100%;
        border-radius: 50%;
        background-color: rgba(0,0,0,0.2);
        box-sizing: border-box;
    }
}

.scrollwrap {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.scroller {
    position: relative;
    height: 100%;
    white-space: nowrap;
    > div {
        white-space: normal;
        text-align: center;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        opacity: 0;
        z-index: 0;
        transition: opacity $timing;
    }
}
.scroll-nav {
    display: block;
    .nav-item {
        display: inline-block;
        font-size: 20px;
        font-weight: 600;
        vertical-align: middle;
        padding: 5px;
        cursor: pointer;
        transition: color 0.3s ease;
        &:hover {
            color: $brand-blue;
        }
        &.active {
            color: $text-highlight;
            &:hover {
                color: $text-highlight;
            }
        }
    }
}
.values {
    &.scroll-nav {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: flex-start;
        margin-top: 0px;
        margin-bottom: 2em;
    }
    .category {
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between; /* top + bottom */
        font-size: 20px;
        width: 48%;
        padding: 1%;
        box-sizing: border-box;
        .name {
            color: $text-light;
            font-weight: 400;
            &.active {
                color: $text-highlight;
            }
        }
    }
}
.value {
    padding-top: 50%; /* space for svg */
    .title {
        font-size: max(2.5em, 30px);
    }
    &.active {
        opacity: 1;
        z-index: 2;
    }
}
/** the values svg animation **/
#values-letters {
    display: block;
    max-width: 100%;
    padding: 5%;
    box-sizing: border-box;
    margin: 0 auto;
    /* letter triggers */
    &.letter-a .a, &.letter-e .e, &.letter-i .i, &.letter-u .u,
    &.letter-w .w, &.letter-c .c,
    &.mode0.letter-o #m0 .o, &.mode1.letter-o #m1 .o {
        animation: draw 10s linear 0.2s forwards;
    }
}


/* ==========================================================================
   Media Queries
   ========================================================================== */

/** ====== display horizontal - desktop ====== **/

@media only screen and (min-width: 961px)
    and (min-height: 701px){
/* tablet-menu width */
    main {
        width: calc(100vw - #{$tablet_menu_width});
        height: 100%;
        left: $tablet_menu_width; /* Should match width of menu bar */
        display: inline-flex;
        flex-direction: row;
        overflow-x: visible;
        flex-flow: wrap;
        writing-mode: vertical-lr;
        position: absolute;
        background-color: $tint;
        > * {
            writing-mode: horizontal-tb;
            height: 100%;
        }
        > div:last-of-type {
            /* stop shapes causing overflow on last pane */
            overflow-x: hidden;
            &.tiles {
                overflow-x: auto;
            }
        }
    }
    .flare-wrap {
        display: inline-flex;
        flex-direction: row;
        overflow-x: visible;
        flex-flow: wrap;
        writing-mode: vertical-lr;
        > * {
            writing-mode: horizontal-tb;
            height: 100%;
        }
    }
    header.top {
        position: fixed;
        top: 0px;
        left: 0px;
        height: 100vh;
        width: $tablet_menu_width;
        flex-direction:column;
        padding:20px;
        align-items:center;
        background:white;
        box-sizing: border-box;
        z-index: 1000;
        .title {
            writing-mode: vertical-rl;
            font-size: 22px;
            line-height: 0; /* zero line height seems to align this correctly on ipad */
            transform: rotate(180deg);
            margin: 1.5em 0;
        }
        .site-links {
            flex-direction:column;
            justify-content:flex-end;
            flex-grow:1;
        }
    }
    .slide-menu {
        display: flex;
        right: auto;
        left: $tablet_menu_width;
        width: 100vw;
        width: calc(100vw - #{$tablet_menu_width});
        transform:translateX(-100vw);
        z-index: 999; /* under header */
        > * {
            flex:1;
            flex-basis:50%;
        }
        .menu-contact-form {
            display: block;
        }
        .menu,
        .menu-contact-form {
            padding-bottom: 4%;
            padding-bottom: 4vw;
        }
        h3 {
            margin-top: 1em; /* same as intro-index */
        }
        .close {
            display: none;
        }
        .menu {
            ul {
                font-size: 20px;
                font-size: max(2.2vw, 20px);
                li li {
                    font-size:16px;
                    font-size: max(1vw, 16px);
                    min-height: 30px;
                    > a {
                        padding-left: 1.5vw;
                    }
                }
            }
        }

    }
    .scroll-hint {
        display: block;
    }
    div.intro { /* project item */
        min-height: 6em;
    }
    .page-heading,
    div.teaser { /* on project item */
        min-height:20vh;
    }
    div.teaser {
        //padding-bottom: 2em;
    }
    .flex-wrap {
        display: flex;
    }
    .flex-wrap {
        width: 100vw;
        width: calc(100vw - #{$tablet_menu_width});
        height: 100%;
    }
    .half-pane,
    .slide-menu .menu {
        width: 50vw;
        width: calc(50vw - #{$tablet_menu_width/2});
    }
    .top-logo {
        left:calc(50vw + #{$tablet_menu_width/2});
    }
    .bottom-logo {
        left:$desktop_menu_width;
        right:calc(50vw - #{$tablet_menu_width/2});
    }
/** content areas **/
    .split-content {
        height: 55vh;
    }
}

@media only screen and (min-width: 1200px){
/** desktop menu width **/
    main {
        width: calc(100vw - #{$desktop_menu_width});
        left: $desktop_menu_width;
    }
    header.top {
        width: $desktop_menu_width;
        width: $desktop_menu_width;
        padding:20px;
    }
    .slide-menu {
        left: $desktop_menu_width;
        width: 100vw;
        width: calc(100vw - #{$desktop_menu_width});
        .menu {
            ul {
                font-size: 30px;
                font-size: max(2.5vw, 30px);
                li li {
                    font-size:18px;
                    font-size: max(1vw, 18px);
                }
            }
        }
    }
    .flex-wrap {
        width: 100vw;
        width: calc(100vw - #{$desktop_menu_width});
    }
    .half-pane,
    .slide-menu .menu,
    .slide-menu .menu-contact-form {
        width: 50vw;
        width: calc(50vw - #{$desktop_menu_width/2});
    }    
    .expanding {
        margin-left: calc(-50vw + #{$desktop_menu_width/2})
    }
    .top-logo {
        left:calc(50vw + #{$desktop_menu_width/2});
    }
    .bottom-logo {
        left:$desktop_menu_width;
        right:calc(50vw - #{$desktop_menu_width/2});
    }
}

@media only screen and (min-height: 800px){
    .intro-index {
        margin-bottom: 4em;
    }
}

/** ====== display vertical  ====== **/

@media only screen and (max-height: 700px) {
/** home **/
    .home_logo {
        margin-left: 0px; /* align left */
    }
}

$contentpad: 30px 10%;
@media only screen and (max-width: 960px), //or
       only screen and (max-height: 700px){
/** general **/
    .site-body {
        height: auto;
        /* hide inactive expanding content */
        overflow: hidden;
    }

    .half-pane {
        padding: $contentpad;
        &.contact {
            padding-bottom: 4em;
        }
    }
    .bg-overlay,
    .bg-overlay-navy,
    .background-dark {
    /* over below expanding pane content */
        z-index: 1;
    }
    .has-mob {
        background-image: none !important;
        .mob-image {
            display: block;
        }
    }
    .bg-overlay {
        min-height: 300px;
    }
    .background-dark, /* current page top */
    .expand-click,
    .half-pane.teaser {
        display: flex; /* for pane-body to fill height */
        flex-direction: column;
        min-height: 50vw;
        border: 0px;
        border-top: $border;
        border-bottom: $border;
    }
    .value-pane .half-pane,
    .careers,
    .careers-image {
        border-top: 0px;
        border-bottom: 0px;
    }
    .careers {
        padding-bottom: 30px;
    }
    main {
        transition: height $slide;
    }
    .expanding.half-pane { /* half-white expanding pane */
        position: static;
        margin-left: 0px;
        margin-top: 0;
        height: auto;
        transition: max-height $slide 0s, padding $slide;        
        z-index: -2;
        max-height: 0vh;
        padding:0px;
        & > * {
            padding-top:1px;      /* prevents margin extending outside container */
            padding-bottom:1px;      /* prevents margin extending outside container */
        }
        overflow:hidden;
        &.active {
            transition: max-height $slide 0s;
            padding: $contentpad;
            padding-bottom: 50px;
            display: block;
            margin-top: 0px;
            z-index: 0;
            max-height: 150vh; /* update in js */
            > .pane-body {
                padding-top: $icon-size; /* close icon */
            }
        }
    }
    .scroll-icon,
    .intro-index.empty {
        display: none;
    }
    .contact-map {
        padding: 0px;
    }
    .flex-wrap {
        > div:first-of-type { /* half-pane */
            padding-bottom: 30px;
        }
    }
    .page-heading {
        font-size: 3em;
        min-height: 0px;
        padding-bottom: 0px;
    }
    .intro-index,
    .scroll-hint,
    .more-link,
    .page-title,
    .blog-categories {
        font-size: 20px;
    }
    .scroll-hint {
        display: none;
    }
    /* make vertical */
    main {
        position: relative;
        left: 0px;
        width: 100%;
        height: auto;
        writing-mode: horizontal-tb;
    }
    .flex-wrap,
    .half-pane {
        font-size: 18px;
        width: 100%;
        height: auto;
        box-sizing: border-box;
    }
    /* related content panes (or index panes)*/
    .teaser,
    .careers-image {
        min-height: 60vw;
    }
/* header */
    header.top {
        position: relative;
        width: 100%;
        height: auto;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        padding: 10px 10%;
        .iso-logo-wrapper{
            display: none;
        }
        .title {
            writing-mode: horizontal-tb;
            transform: rotate(0deg);
        }
        .site-links {
            display: none;
        }
        a.home {
            padding-left: 20px;
            margin-top: 0px;
        }
    }
    a.logo {
        padding: 0px;
        h1 {
            margin-bottom: 0px;
        }
    }
    #menu-button {
        order: 1;
        margin-left: auto;
    }
/* mobile menu */
    .slide-menu {
        display: block;
        left: auto;
        right: 0px;
        width: 400px;
        transform: translateX(400px);
        overflow-y: auto;
        z-index: 1000; /* over header */
        ul {
            font-size: 18px;
        }
        li {
            &.top {
                > a {
                    font-size: 22px;
                }
            }
            ul {
                background-color: tint($text-dark, 5%);
            }
        }
        .menu,
        .menu-contact-form {
            width: 100%;
        }
        .menu {
            padding: 0px; /* items have padding */
            margin: 0px;
            h3 {
                font-size: 30px;
                margin-top: 0px;
                cursor: pointer;
            }
            .close {
                display: block;
            }
        }
        .menu-contact-form {
            display: none;
        }
        .contact.half-pane {
            padding: 12px 18px;
            padding-top: 5px;
            padding-left: 30px;
            background-image: none !important;
            background-color: transparent;
            &:before {
                display: none;
            }
            .intro-index,
            form,
            .mob-image{
                display: none;
            }
        }
    }
/** home **/
    .t-home a.logo {
        display: none;
    }
    #home {
        height: auto;
        padding: 30px 0px;
    }
    .home-logo {
        width: 500px;
    }
    .home_content {
        position: relative;
        display: block;
        height: auto;
        .half-pane {
            width: 100%;
            height: auto;
            padding-bottom: 30px;
            &:first-of-type {
                min-height: 0px;
            }
        }
    }
    .tiles {
        width: 100% !important;
    }
    .flex-wrap {
        .half-pane {
            padding-bottom: 30px;
            &:last-of-type {
                padding-bottom: 6em;
                &.scroller-pane {
                    padding-bottom: 10px;
                }
            }
            &.nav-pane {
                padding-bottom: 40px;
            }
        }
    }
/** values **/
    .nav-pane {
        order: 1;
    }
    .half-pane.nav-pane {
        padding-top: 0px;
        .intro-index {
            display: none;
        }
        .page-summary {
            order: 1;
        }
    }
    div.bg-circle.scroll-shape {
        position: absolute;
        left: 0px;
        transform: translateX(0px) !important;
    }
    .values.scroll-nav {
        justify-content: center;
        .category {
            align-items: center;
            .name {
                text-align: center;
            }
        }
    }
    .scroller-pane {
        .intro-index {
            visibility: visible;
        }
    }
    .scrollwrap {
        height: auto;
    }
    .value { /* min-height set in js */
        padding-top: 50%; /* for svg */
        .title {
            margin-top: 5px;
        }
    }
/** split content **/
    .split-content {
        display: block;
        height: auto;
        box-sizing: border-box;
    }
/** page **/
    .intro-index {
        margin-bottom: 1em;
    }
/** indexes **/
    .pages {
        text-align: center;
        .button {
            margin: 5px;
        }
    }
    .vertical-only {
        display: block;
    }
/* project */
    .proj-anchor {
        display: none;
    }
/** footer **/
    footer {
        display: block;
    }
}

$contentpad: 30px;
$icon-size: 30px;
@media only screen and (max-width:760px) {
/* general */
    .half-pane {
        padding: $contentpad;
        padding-bottom: 60px;
        &.contact-map {
            padding: 0px;
        }
        &.careers {
            padding-bottom: 30px;
        }
    }

    header.top {
        padding: 10px 30px;
        .iso-combined{
            display: inline-block!important;
        }
        .iso{
            display: none;
        }
    }
    .page-heading {
        font-size: 2em;
        min-height: 0px;
    }
    .intro-index {
        margin-bottom: 1em;
    }
    a {
        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;
    }
/** values **/
    div.bg-circle.scroll-shape {
        left: -15px;
        right: -15px;
        width: auto;
    }
/** embedded content - half-pane, half-white **/
    .expand-click,
    .half-pane.teaser {
        .page-heading { /* space for icon */
            padding-right: $icon-size;
        }
    }
    .expander-open,
    .profile-close,
    .expander-close {
        .icon {
            width: $icon-size;
            height: $icon-size;
            &:before {
                height: 15px;
                margin-top: -7.5px;
            }
            &:after {
                width: 15px;
                margin-left: -7.5px;
            }
        }
    }
    .expanding.half-pane.active {
        padding: $contentpad;
        padding-bottom: 60px;
        .pane-body {
            padding-top: $icon-size;
        }
    }
}

@media only screen and (max-width: 500px){
/* header */
    header.top .title {
        display: none;
    }
/** mobile menu **/
    .slide-menu {
        width: 100%;
        transform: translateX(100%);
        .menu h3,
        h3 {
            font-size: 22px;
        }
    }
/** values nav **/
    .values {
        .category {
            flex-grow: 1;
            width: auto;
            min-width: 48%;
        }
    }
}

@media only screen and (max-width: 300px){
    .home_content h1 {
        font-size: 28px;
    }
    .home_content .half-pane,
    .page-heading {
        font-size: 1.5em;
    }
}
/* ==========================================================================
   Helper classes
   ========================================================================== */

.hidden {
    display: none !important;
    visibility: hidden;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

/* ==========================================================================
   Print styles
   ========================================================================== */

@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}

@import '_forms.scss';
@import '_contact.scss';
@import '_tiles.scss';
@import '_gallery.scss';
@import '_alerts.scss';