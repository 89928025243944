@import '_constants.scss';

/* item content gallery */
.gallery {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 100%;
    &.intro-index {
        flex-direction: column;
    }
}
.gallery-image {
    height:50% !important;
    object-fit:cover;
}

/* project gallery */
.gallery-thumbnails {
    box-sizing: border-box;
    img {
        margin:1vw;
        height:35vh !important;
    }
    text-align: center;
    &.intro-index {
        display: block;
    }
}
.gallery-stage {
    height: 100vh;
}

/** ====== display horizontal - desktop ====== **/

@media only screen and (min-width: 961px)
    and (min-height: 651px){
    .gallery-images {
        width: 50vw;
        /*width: calc(50vw - #{$tablet_menu_width/2});*/
    }
    .gallery-thumbnails {
        writing-mode: vertical-lr;
    }
}

@media only screen and (min-width: 1200px){
    .gallery-images {
        width: 50vw;
        /*width: calc(50vw - #{$desktop_menu_width/2});*/
    }
}

/** ====== display vertical  ====== **/

@media only screen and (max-width:960px), //or
       only screen and (max-height:650px){
/** item content galleries **/
    .gallery {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .gallery-image {
        width: 50%;
        border: 2px solid white;
        box-sizing: border-box;
    }
/** projects */
    .gallery-thumbnails {
        width: 100% !important;
    }
}