@import '_constants.scss';

.tiles {
    position: relative;
    z-index: 3; /* over shapes */
    display: flex;
    /* flex-direction: column; */
        /* Note: flex column has width bug, row doesn't */
        /* use row but change the writing mode so width works */
    writing-mode: vertical-lr;
    flex-wrap: wrap;
    /* width in media */
    height: 100vh;
    padding:0;
    background-color: $tint;
    border-left: $border;
    border-right: $border;
    box-sizing: border-box;
    &.staff {
        position: relative;
        transition: width $timing;
        overflow: hidden;
        > * { /* articles and divs */
            position: relative;
            writing-mode: horizontal-tb;
        }
        > div {
            /* pink - every 2nd and 6th */
            &:nth-of-type(6n+2) .tile::before,
            &:nth-of-type(6n) .tile::before{
                background: $brand-pink;
            }
            /* yellow - every 3rd and 4th */
            &:nth-of-type(6n+3) .tile::before,
            &:nth-of-type(6n+4) .tile::before{
                background: $brand-yellow;
            }
            &.active {
                .tile {
                    &:after, /* darken */
                    > span {
                        opacity: 0;
                    }
                }
                /* JS shows desktop-pane on desktop */
            }
        }
        .page-heading {
            min-height: 0px;
            padding-bottom: 0px;
        }
        .position {
            color: $text-highlight;
        }
        &.shield {
            > div:last-of-type {
                /* background shield */
                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 200%;
                    top: 100%;
                    left: 0px;
                    background-color: $tint;
                    z-index: 2; /* cover the desktop-pane */
                }
            }
        }
        .profile {
            z-index: 2;
        }
        .bio {
            /* shown in mobile */
            display: none;
        }
        .tile { /* bottom */
            justify-content: flex-end;
            background-position: top;
            &:after, /* darken */
            > span {
                transition: opacity $timing;
                padding: 0px;
            }
            .hover {
                padding-top: 10px;
            }
        }
        .js-pane { /* populated by js */
            position: relative;
            z-index: 0;
            &.active {
                z-index: 1;
            }
            /* last element in tiles wrapper has both classes */
            &.tablet {
                display: none;
            }
            &.desktop {
                display: block;
                margin-left: -100%;
                height: 100vh;
                box-sizing: border-box;
                transition: margin-left $slide;
                &.active {
                    margin-left: 0px;
                }
            }
            > div { /* content put inside */
                display: flex;
                flex-direction: column; /* to push close down */
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: opacity $timing;
                &.fade-in {
                    opacity: 1;
                }
                .more-link {
                    margin-top: auto;
                }
            }
        }
    }
    /** related or content lists **/
    .tile {
        writing-mode: horizontal-tb;
        width: 40vh; /* wider than tall */
        height: 33.3vh;
        flex-basis: 33.3%;
        position:relative;
        background-color: grey;
        background-size:cover;
        background-position:center;
        display: inline-flex;
        font-size: 18px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color:$text-light;
        text-decoration:none;
        padding: 2%;
        border: $border;
        box-sizing: border-box;
        span {
            position: relative;
            display: block;
            text-align: center;
            padding: 10px 0px;
            z-index: 1;
        }
        .title {
            font-size: 1.5em;
            padding-top: 15%;
        }
        &::before, /* colour hover */
        &::after { /* darken */
            content:"";
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            z-index: 0;
        }
        &::before,
        .hover {
            opacity: 0;
            transition: opacity $timing;
        }
        &:after {
            @include darken_before;
        }
    }
    .tile::before {
        background:$brand-blue;
    }
    /* pink - every 2nd and 6th */
    .tile:nth-child(6n+2)::before,
    .tile:nth-child(6n)::before{
        background: $brand-pink;
    }
    /* yellow - every 3rd and 4th */
    .tile:nth-child(6n+3)::before,
    .tile:nth-child(6n+4)::before{
        background: $brand-yellow;
    }
    .tile:hover {
        &::before {
            opacity: 0.8;
        }
        .hover {
            opacity: 1;
        }
    }
    .not-found {
        margin:10%;
    }
}

/** ====== display horizontal - desktop ====== **/

@media only screen and (min-width:961px), //or
       only screen and (min-height: 701px) {
    .tiles { /* now in chunks of 6, min = one tile width */
        min-width: 25vw;
        min-width: calc(25vw - #{$tablet_menu_width/4});
        .tile {
            width: 25vw;
            width: calc(25vw - #{$tablet_menu_width/4});
        }
    }
}

@media only screen and (min-width: 1200px){
    .tiles {
        min-width: 25vw;
        min-width: calc(25vw - #{$desktop_menu_width/2});
        .tile {
            width: 25vw;
            width: calc(25vw - #{$desktop_menu_width/4});
        }
    }
}

/** ====== display vertical  ====== **/

@media only screen and (max-width: 960px), //or
       only screen and (max-height: 700px){
    .t-news,
    .t-team {
        .tiles {
            display: flex;
        }
    }
    /* the rest are hidden but keep css */
    .tiles {
        display: none;
        writing-mode: horizontal-tb;
        width: 100% !important;
        min-width: 0px;
        height: auto;
        .tile {
            width: 33.33%;
            height: auto;
            min-height: 200px;
            min-height: 32vw;
        }
        &.staff {
            .position {
                margin-top: 0px;
            }
            .profile {
                min-width: 33.33%;
                .tile {
                    width: 100%;
                }
            }
            .js-pane {
                &.desktop,
                &.tablet { /* expand down */
                    left: 0px;
                    top: 0px;
                    margin-top: -100%;
                    width: 100%;
                    height: auto;
                    transition: margin-top $timing;
                    padding-bottom: 60px;
                    &.active {
                        margin-top: 0px;
                    }
                }
            }
            &.shield { /* shield across not down */
                 > div:last-of-type::after {
                    top: 0px;
                    left: 100%;
                    width: 200%;
                    height: 100%;
                 }
            }
        }
    }
}

@media only screen and (max-width: 760px) {
    .tiles {
        .tile {
            width: 50%;
            min-height: 200px;
            min-height: 50vw;
        }
        /* reset */
        .tile:nth-child(6n+2)::before,
        .tile:nth-child(6n)::before,
        .tile:nth-child(6n+3)::before,
        .tile:nth-child(6n+4)::before {
            background: $brand-blue;
        }
        /* new pattern */
        .tile:nth-child(3n+2)::before {
            background:$brand-yellow;
        }
        .tile:nth-child(3n+3)::before {
            background:$brand-pink;
        }
        &.staff {
            .profile {
                width: 50%;
            }
            .js-pane {
                &.desktop {
                    display: none;
                }
                 &.tablet {
                    display: block;
                }
            }
        }
    }
}
@media only screen and (max-width: 500px) {
    .tiles {
        &.staff {
            .js-pane,
            .profile-close {
                display: none;
            }
            .profile {
                width: 100%;
                background-color: inherit;
                .tile {
                    display: flex;
                    width: 500px;
                    min-height: 400px;
                    max-width: 100%;
                    margin: 0 auto;
                }
                .bio {
                    display: block;
                    padding: 30px;
                    padding-bottom: 60px;
                }
            }
        }
    }
}
@media only screen and (max-width: 300px) {
    .tiles {
        .tile {
            width: 100%;
            min-height: 150px;
            min-height: 100vw;
            .title {
                font-size: 20px;
            }
        }
    }
}

/* Blog/news page customisations */

@media only screen and (min-width: 961px), only screen and (min-height: 701px) {
    .t-news .tiles {
        /* this just lines things up a bit better. There is no content after the tiles to full up the screen, so when there is only one column of
        tiles we don't want it centered in the remaining flex space. We also don't want to see scroll bar showing "under" it, so we want it to take up
        at least the remaining half of the screen */
        align-self: flex-start;
        min-width: calc(50vw - #{$tablet_menu_width/2});
        align-content: flex-start;
    }
}
@media only screen and (min-width: 1200px) {
    .t-new .tiles {
        min-width: calc(50vw - #{$desktop_menu_width/2});;
    }
}