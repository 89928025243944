@import '_colours.scss';
/*============ Contact ============*/
.contact {
    .liability {
        font-weight: 400;
        font-size: 14px !important;
        margin-bottom: 10px;
    }
}
.contact-pane  {
    .page-subtitle {
        min-height:0;
    }
}
form.contact-form {
    input,
    select,
    textarea {
        color: $text-light;
        background-color: rgba(0,0,0,0.5);
    }
    p.js-float-label-wrapper label {
        color: $text-light;
    }
}
.map {
    width: 100%;
    height: 100vh;
    .kms-loader {
        position: absolute;
        top:50%;
        margin-top:-50px;
        left:50%;
        margin-left:-50px;
    }
}
.address-details {
    .row,
    .opening-hours {
        display:block;
    }
    a {
        color:inherit;
        text-decoration:None;
    }
    table {
        td {
            padding: 5px;
            &.label {
                padding-left: 0px;
                padding-right: 10px;
            }
        }
    }
}

/** horizontal **/
@media only screen and (min-width: 961px)
                   and (min-height: 701px){
    .address-details {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        > div {
            display: inline-block;
            vertical-align: top;
            width: 48%;
            box-sizing: border-box;
            > p:first-of-type {
                margin-top: 5px;
            }
        }
    }
}

/** vertical **/
@media only screen and (max-width: 960px), //or
       only screen and (max-height: 700px) {
    .contact-pane { /* embed, not menu contact */
        &.half-pane {
            padding-top: 40px;
        }
    }
    .contact .small {
        margin-top: 1em;
    }
}

@media only screen and (max-width: 760px){
    .map {
        height: 0px;
        min-height: 250px;
        padding-top: 60%;
        box-sizing: border-box;
    }
}

@media only screen and (max-width: 300px){
    .address-details {
        table {
            tr {
                display: block;
                padding: 8px 0px;
            }
            th, td {
                display: block;
                padding: 0px;
                &.label {
                    padding-right: 0px;
                    padding-bottom: 2px;
                }
            }
        }
    }
}