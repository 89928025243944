@import '_colours.scss';
@import '_constants.scss';

form {
    font-size: 16px;
    font-size: max(0.9vw,16px);
    font-weight: normal;
    .half {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        justify-content: space-evenly;
        justify-content: space-between;
        align-items: flex-start;
        box-sizing: border-box;
        margin-bottom: -0.25em; /* flex items margins don't collapse */
        > p,
        > div {
            display: inline-block;
            width: 49%;
            box-sizing: border-box;
        }
        > p {
            margin: 0.25em 0px; /* flex items margins don't collapse */
        }
        > div {
            align-self: stretch;
        }
    }
    input, textarea, select {
        font-family: muli, sans-serif;
        font-size: inherit;
        font-weight: 300;
        color: #808080;
        background-color:#F2F2F2;
        border:none;
        width:100%;
        padding:10px;
        padding-top: 15px; /* for label */
        box-sizing:border-box;
    }
    select {
        padding-top: 0px;
        padding-bottom: 0px;
        color: #5a5a5a;
        min-height: 40px;
    }
    textarea {
        /* expanding height will likely go out of bounds */
        height: 5em;
    }
    label {
        color: inherit;
        font-family: muli, sans-serif;
        font-weight: 400;
    }
    p {
        margin: .5em 0px;
        &.js-float-label-wrapper {
            position: relative;
            label {
                color: #5a5a5a;
                position:absolute;
                left:10px;
                top:10px;
                transition: all $timing;
            }
        }
    }
    p.js-float-label-wrapper {
        &.focused,
        &.active,
        &.populated {
            label {
                top:1px;
                left:2px;
                font-size:10px;
            }
        }
    }
    .file-upload-container {
        display: flex;
        flex-wrap: wrap;
    }
    .file-upload-info {
        font-size: 12px;
        width: 100%;
    }
    a {
        color: $brand-blue;
        padding: 1% 0px;
        &.file-remove {
            color: $brand-pink;
            margin-left: auto;
        }
    }
    button {
        position: relative;
        font-size: 16px;
        font-size: max(1vw, 16px);
        font-family: muli, sans-serif;
        font-weight: 600;
        display: block;
        color: $text-light;
        text-align: inherit;
        width: auto;
        padding: 10px 15px;
        background:transparent;
        box-sizing: border-box;
        border: 2px solid #FFF;
        max-width: 100%;
        margin-right: auto;
        cursor: pointer;
        transition: color 0.3s ease, border-color 0.3s ease;
        &:hover {
            color: $brand-blue;
            border-color: $brand-blue;
        }
    }
    &.contact-form {
        > p {
            &:nth-last-of-type(2){ /* textarea */
                margin-bottom: 0px;
            }
            &:last-of-type { /* button */
                margin-bottom: 0.5em;
            }
        }
    }
    &.careers-form {
        textarea {
            height: 4em;
        }
    }
    .recaptcha-notice {
        color: inherit;
        a {
            color: inherit;
        }
    }
}

/** vertical scrolling **/
@media only-screen and (max-width: 960px){
    form {
        font-size: 18px;
        button {
            font-size: 18px;
        }
    }
}

/** mobile **/
@media only screen and (max-width: 500px){
/** form stack **/
    form {
        .half {
            display: block;
            width: 100%;
            margin-bottom: 0px;
            > p,
            > div {
                width: 100%;
                margin: 0.5em 0px;
            }
        }
    }
}
